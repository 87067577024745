import React from "react"
import { Link } from "gatsby"
import { motion, useAnimation } from "framer-motion"

const ProjectItem = ({ key, slug, title, coverPhoto, style }) => {
  const controls = useAnimation()
  const theatreVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <li key={key}>
      <Link
        to={`/projects/${slug}`}
        onMouseOver={() => controls.start(theatreVariants.visible)}
        onMouseLeave={() => {
          controls.start(theatreVariants.hidden)
        }}
      >
        {title}
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 8.5L6 8.5L4.37114e-07 8.5M14 8.5L9 13.5M14 8.5L9 3.5"
            stroke="#9FC774"
            stroke-width="1"
          />
        </svg>
      </Link>

      {coverPhoto && (
        <motion.div
          animate={controls}
          initial={theatreVariants.hidden}
          className={style}
        >
          <img src={coverPhoto.file.url} alt={coverPhoto.title} />
        </motion.div>
      )}
    </li>
  )
}

export default ProjectItem
