import React from "react"
import jobItem from "./job-item.module.scss"
import ProjectItem from "./ProjectItem"

const JobItem = ({
  title,
  description,
  isASideJob,
  endDate,
  startDate,
  isMyCurrentJob,
  projects,
  jobURL,
}) => {
  return (
    <div className={jobItem.content}>
      <div className={jobItem.title}>
        <h2>{title}</h2>
        {isASideJob && (
          <div className={jobItem.titleTag}>
            <span>Side Project</span>
          </div>
        )}
      </div>
      <div>
        {!isMyCurrentJob && (
          <div className={jobItem.year}>
            {endDate ? `${endDate} / ${startDate}` : `${startDate}`}
          </div>
        )}{" "}
        {/* renderizar somente se não isMyCurrentJob */}
      </div>
      <p>{description}</p>
      <ul>
        {projects &&
          projects
            .sort((a, b) => {
              return a.year < b.year ? 1 : -1
            })
            .map((project, index) => {
              if (project.isVisible) {
                return (
                  <ProjectItem
                    key={index}
                    title={project.title}
                    slug={project.slug}
                    coverPhoto={project.coverPhoto}
                    style={jobItem.theatre}
                  />
                )
              } else {
                return <p key={index}>{project.title}</p>
              }
            })}
        {jobURL && (
          <li className={jobItem.listItem}>
            <a href={jobURL} target="_blank" rel="noreferrer">
              Visit Website{" "}
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 8.5L6 8.5L4.37114e-07 8.5M14 8.5L9 13.5M14 8.5L9 3.5"
                  stroke="#9FC774"
                  stroke-width="1"
                />
              </svg>
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default JobItem
