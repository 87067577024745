import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import indexStyle from "./index.module.scss"
import Head from "../components/Head"
import CurrentJobs from "../components/CurrentJobs"
import PastJobs from "../components/PastJobs"

import "../styles/index.scss"

const IndexPage = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         author
  //         job
  //       }
  //     }
  //     allContentfulProjectPage(sort: { fields: year, order: DESC }) {
  //       edges {
  //         node {
  //           title
  //           slug
  //           year(formatString: "YYYY")
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout>
      <Head title="Portfolio" />
      <div className={indexStyle.content}>
        <div className={indexStyle.header}>
          <h1>Hello. I am Pedro Pim, designer working at Nubank</h1>
          <p>Previously XP, iFood, Easynvest, Huge and Globo.com</p>
        </div>

        <div>
          <CurrentJobs />
          <PastJobs />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
