import React from "react"
import JobItem from "./JobItem"
import { graphql, useStaticQuery } from "gatsby"
import jobListStyle from "./jobs-list.module.scss"

const CurrentJobs = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulJob(
        filter: { isMyCurrentJob: { eq: true } }
        sort: { fields: startDate, order: ASC }
      ) {
        nodes {
          name
          description
          isASideJob
          endDate(formatString: "YYYY")
          startDate(formatString: "YYYY")
          isMyCurrentJob
          jobUrl
          project_page {
            title
            coverPhoto {
              file {
                url
              }
            }
            year
            isVisible
            slug
          }
        }
      }
    }
  `)

  return (
    <div className={jobListStyle.content}>
      {/*<div className={jobListStyle.title}>currently</div> */}
      {data.allContentfulJob.nodes.map((job, index) => (
        <JobItem
          key={index}
          title={job.name}
          description={job.description}
          isASideJob={job.isASideJob}
          isVisible={job.isVisible}
          endDate={job.endDate}
          startDate={job.startDate}
          isMyCurrentJob={job.isMyCurrentJob}
          projects={job.project_page}
          jobURL={job.jobUrl}
        />
      ))}
    </div>
  )
}

export default CurrentJobs
